@font-face {
    font-family: wf_segoe-ui_light;
    src: local("Segoe UI Light"), local("Segoe WP Light"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/light/latest.eot?#iefix) format("embedded-opentype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/light/latest.woff) format("woff"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/light/latest.svg#web) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: wf_segoe-ui_normal;
    src: local("Segoe UI"), local("Segoe"), local("Segoe WP"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/normal/latest.eot?#iefix) format("embedded-opentype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/normal/latest.svg#web) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: wf_segoe-ui_Semibold;
    src: local("Segoe UI Semibold"), local("Segoe Semibold"), local("Segoe WP Semibold"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/Semibold/latest.eot?#iefix) format("embedded-opentype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/Semibold/latest.woff) format("woff"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/Semibold/latest.ttf) format("truetype"), url(//i.s-microsoft.com/fonts/segoe-ui/west-european/Semibold/latest.svg#web) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: wf_icons_normal;
    src: url(//i.s-microsoft.com/fonts/icons/homepage/normal/2.eot?#iefix) format("embedded-opentype"), url(//i.s-microsoft.com/fonts/icons/homepage/normal/2.woff) format("woff"), url(//i.s-microsoft.com/fonts/icons/homepage/normal/2.ttf) format("truetype"), url(//i.s-microsoft.com/fonts/icons/homepage/normal/2.svg#web) format("svg");
    font-weight: normal;
    font-style: normal;
}
