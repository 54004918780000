html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    /*padding-top: 50px;*/
    /*padding-bottom: 20px;*/
    font-family: wf_segoe-ui_normal,Segoe UI,Tahoma,Arial,sans-serif !important;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

footer a {
	color: #616161 !important;
}

div.main-content {
    min-height: 450px !important;
    /*_height: 500px !important;*/
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.wrap-div {
    overflow: auto;
}

pre {
    border: 0;
}

a {
    color: #0067b8;
}

.mscom-footer {
    background-color: #00BCF2 !important;
}

.mscom-footer2 {
    background-color: white !important;
    color: black !important;
    padding-top: 17px !important;
    /*padding-bottom:5px;*/
}

    .mscom-footer2 a {
        color: black !important;
    }

    .mscom-footer2 .heading {
        color: black !important;
    }


a.msopentech-link, a.msopentech-link:hover, a.msopentech-link:active {
    text-decoration: none;
}

.tou {
    font-family: wf_segoe-ui_light;
}

ul.toulist {
    list-style-type: disc;
    margin-left: 1em;
}

    ul.toulist li {
        font-family: wf_segoe-ui_light;
    }

.mscom-header {
    margin-top: 15px;
    padding-bottom: 5px;
}

h2.repo-name {
    font-family: wf_segoe-ui_light;
}

.product-icon {
    margin-top: 15px;
    margin-bottom: 15px;
}

.position-hr-min-sep {
    display: inline-block;
    float: left;
    font-size: 35px;
    width: 1px;
    height: 34px;
    line-height: 34px;
    margin-top: -5px;
}


input.frmSubmitted.ng-invalid,
select.frmSubmitted.ng-invalid {
    border: 1px solid #FF0000;
}

.justify-evenly {
    justify-content: space-evenly;
}

.footer {
    background-color: #eee;
    height: 130px;
    width: 100%;
    margin-top: 10px;
}

.footer ul {
    height: 50px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 47px;
}

.footer .location-logo {
    background: url(../images/globe-black.png) left top no-repeat;
    width: 30px;
    height: 30px;
    display: inline-block;
    float: left;
}

.footer .footer-logo {
    background:url(../images/footer-logo.png) left top no-repeat;
    width:89px;
    height:25px;
    clear:both
}

.footer .menu-link-container .menu-link {
    text-decoration: underline;
}

.header-logo {
    display: block;
    width: 102px;
    height: 24px;
    margin: .6875rem 0 0;
    margin-bottom: 1.25rem;
}

.site-branding {
    display: block;
    float: left;
    width: 65.6891495601%;
    margin-right: 2.9325513196%;
    margin-bottom: .3125rem;
}

.site-title {
    font-family: wf_segoe-ui_light,Segoe UI,Tahoma,Arial,sans-serif;
    font-weight: 100;
    display: inline;
    float: left;
    text-decoration:none!important;
    margin-top: .6875rem;
    outline:none;
}

.site-title a, .site-title a:visited {
    font-size: 1.875rem;
    line-height: 1;
    text-decoration: none!important;
    color: #2d2d2d;
    outline:none;
}

.wrapper {
    min-height:100%;
    position:relative;
}

.content {
    padding-bottom:130px;
}

footer {
    position:absolute;
    bottom:0;
    left:0;
    height:130px;
    width:100%;
}

a.m-skip-to-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -2;
}

a.m-skip-to-main:focus {
  left: 0;
  top: 0;
  padding: 3px;
  background: #ffc;
  display: block;
  min-width:170px;
  min-height:110px;
  border: 1px solid #990000;
}

.highlighted-link {
  font-weight: bold;
  font-style: italic;
}

.menu-copyright-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ms-DetailsList {
    overflow: visible visible;
}